import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Container from 'react-bootstrap/Container'

import 'bootstrap/dist/css/bootstrap.min.css'

import { Topbar } from './components/topbar'

import { routesConfig } from './pages/routesConfig'

export const App = () => {
  return (
    <>
      <Topbar routes={routesConfig} />
      <Container>
        <Switch>
          {routesConfig.map((route) =>
            route.path ? <Route key={route.id} {...route} /> : <Route key={route.id} render={route.render} />,
          )}
        </Switch>
      </Container>
    </>
  )
}
