import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import { RouteType } from '../pages/routesConfig'

type TopbarProps = {
  routes: ReadonlyArray<RouteType>
}

export const Topbar: FC<TopbarProps> = ({ routes }) => (
  <Navbar expand="lg" bg="dark" variant="dark">
    <Container>
      <Navbar.Brand as={Link} to="/">
        Pools Manager
      </Navbar.Brand>
      <Nav className="mr-auto">
        {routes
          .filter((route) => !route.hideInNav && route.path)
          .map((route) => (
            <Nav.Link key={route.id} as={Link} to={route.path!}>
              {route.name}
            </Nav.Link>
          ))}
      </Nav>
    </Container>
  </Navbar>
)
