import React, { FC, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { Formik, Field, Form } from 'formik'

import { CurrenciesEnum, currencyToEmoji } from '../../helpers/currencies'
import { MemberType } from '../../helpers/members'

const members: ReadonlyArray<MemberType> = [
  {
    id: 'mahumachinen',
    username: '@mahumachinen',
    name: 'Mahumachinen',
    currency: CurrenciesEnum.DIRECT,
  },
  {
    id: 'didier-d',
    username: '@dddidier',
    name: 'Didier D',
    currency: CurrenciesEnum.EURO,
  },
]

export const MembersPage = () => (
  <Row>
    <Col>
      <ListGroup>
        <MemberRow />
        {members.map((member) => (
          <MemberRow key={member.id} member={member} />
        ))}
      </ListGroup>
    </Col>
  </Row>
)

type MemberRowProps = {
  member?: MemberType
}

const MemberRow: FC<MemberRowProps> = ({ member }) => {
  const [isEditing, setIsEditing] = useState(!Boolean(member))

  if (!member || isEditing)
    return (
      <ListGroup.Item>
        <Formik
          initialValues={{ username: member?.username, name: member?.name, currency: member?.currency }}
          onSubmit={async (values) => {
            await new Promise((resolve) => setTimeout(resolve, 500))
            alert(JSON.stringify(values, null, 2))
            setIsEditing(false)
          }}
        >
          <Form>
            <Field name="username" type="text" />
            <Field name="name" type="text" />
            <Field name="currency" type="text" />
            <Button type="submit" variant="primary" size="sm" className="ml-auto">
              Save
            </Button>
          </Form>
        </Formik>
      </ListGroup.Item>
    )

  return (
    <ListGroup.Item>
      <b>{member.name}</b> (<em>{member.username}</em>) {currencyToEmoji(member.currency)}
      <Button variant="primary" size="sm" className="ml-auto" onClick={() => setIsEditing(true)}>
        Edit
      </Button>
    </ListGroup.Item>
  )
}
