export enum CurrenciesEnum {
  AUD = 'aud',
  DIRECT = 'direct',
  EURO = 'euro',
  USD = 'usd',
}

export const currencyToEmoji = (currency: CurrenciesEnum) => {
  switch (currency) {
    case CurrenciesEnum.AUD:
      return '🇦🇺'
    case CurrenciesEnum.DIRECT:
      return '🏴‍☠️'
    case CurrenciesEnum.EURO:
      return '🇪🇺'
    case CurrenciesEnum.USD:
      return '🇪🇺'
    default:
    // This should not happen
  }
}
