import React from 'react'
import { Redirect } from 'react-router-dom'

import { MembersPage } from './members'

export type RouteType = {
  id: string
  name?: string
  path?: string
  component?: () => JSX.Element
  render?: () => JSX.Element
  hideInNav?: boolean
}

export const routesConfig: ReadonlyArray<RouteType> = [
  {
    id: 'pools',
    name: 'Pools',
    path: '/pools',
    component: () => <div>Pools</div>,
  },
  {
    id: 'members',
    name: 'Members',
    path: '/members',
    component: MembersPage,
  },
  {
    id: 'root',
    render: () => <Redirect to="/pools" />,
    hideInNav: true,
  },
]
